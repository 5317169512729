import * as React from 'react';
import './user-registration.css';
import { CSSTransition } from 'react-transition-group';
import { IdentificationRegistry } from '../../services/identification-registry';
import { Subject } from 'rxjs';
import { Translation } from 'react-i18next';
import { MIDSDKBiometricMethod, UserManagementObserver } from '../../../../assets/js/mobbid-sdk-core-1.30.2.js';
import { FaceDetectorObserver } from '../../../../assets/js/mobbid-sdk-face-1.30.2.js';
import { FaceUIObserver } from '../../../../assets/js/mobbid-sdk-face-1.30.2.js';
import { displayLastVideoImage } from './../../../ui/common/face-utils';

export class UserRegistration extends React.Component<any, any> {
    private registry: IdentificationRegistry;
    private unsubscribe$ = new Subject();
    private faceUIObserver = new FaceUIObserver();

    constructor(props: any) {
        super(props);
        this.state = {
            capturedFace: false,
            imageToRegister: null,
            emailFormValue: null,
            nameFormValue: null,
            initialized: false,
            registering: false,
            selectingCamera: true,
            delayMode: this.props.delayMode,
            successfulRegistration: false,
            erroneousRegistration: false,
            timeout: false,
            userFeedback: '',
            processCancelled: false,
        };
        this.registry = new IdentificationRegistry(this.props.registrationURL);
    }

    componentDidMount(): void {
        const initContainer = this.props.midSDK.setContainer(
            MIDSDKBiometricMethod.FACE,
            document.getElementById('faceContainer'),
            this.props.language,
            this.faceUIObserver,
        );
        initContainer.then(result => {
            this.setState({ ...this.state, initialized: true });
            const faceDetectorObserver = new FaceDetectorObserver();
            this.props.midSDK.detect(MIDSDKBiometricMethod.FACE, faceDetectorObserver);

            // Face Detector Observer
            faceDetectorObserver.errors().subscribe(error => {
                this.onError(error, false);
            });
            faceDetectorObserver.result().subscribe(result => {
                this.onFaceDetected(result);
            });

            // Face UI Observer
            this.faceUIObserver.progress().subscribe(progress => {
                this.setState({ ...this.state, error: undefined });

                if (progress >= 1) {
                    this.setUIText('FACE_DETECTED');
                    if (!this.isDelayMode()) {
                        displayLastVideoImage();
                        document.getElementById('cancel-detection-container')?.remove();
                    }
                } else if (progress > 0) {
                    this.setUIText('DETECTING_FACE');
                } else {
                    this.setUIText('SEARCHING_FOR_FACE');
                }
            });
            this.faceUIObserver.errors().subscribe(error => {
                console.log('ERROR: ' + error);
                this.setUIText(error[0]);
            });
            this.faceUIObserver.events().subscribe(event => {
                console.log('EVENTS: ' + event);
                this.setUIText(event);
            });
            this.faceUIObserver.timeouts().subscribe(timeout => {
                console.log('TIMEOUTS: ' + timeout);
            });

            this.configureMobbidSDKElements();
        });
    }

    private configureMobbidSDKElements = (): void => {
        const startDetectionBtn = document.getElementById('start-detection-button');
        const cancelDetectionBtn = document.getElementById('cancel-detection-button');

        startDetectionBtn.addEventListener('click', () => {
            this.setState({ ...this.state, delayMode: false });
            this.setState({ ...this.state, selectingCamera: false });
            this.props.midSDKFace.stopDelayMode();
            document.getElementById('selector-elements-container').remove();
            cancelDetectionBtn.classList.remove('hidden');
            this.setUIText('SEARCHING_FOR_FACE');
        });

        cancelDetectionBtn.addEventListener('click', () => {
            this.props.midSDKFace.cancelDetection();
            document.getElementById('cancel-detection-container')?.remove();
            this.setUIText('CANCEL_PROCESS');
            this.setState({ ...this.state, processCancelled: true });
        });

        if (window?.mobbid?.devices) {
            this.createCameraSelector(window.mobbid.devices);
        }
    };

    private createCameraSelector = devices => {
        const selector = document.createElement('select');
        selector.id = 'mobbid-camera-selector';

        devices.forEach(device => {
            const option = document.createElement('option');
            option.value = device.deviceId;
            option.text = device.label;
            selector.appendChild(option);
        });

        selector.addEventListener('change', async event => {
            const target = event.target as HTMLInputElement;
            const cameraId = target.value;
            await this.props.midSDKFace.setCamera(cameraId);
        });

        document.getElementById('mobbid-camera-selector-container').append(selector);
    };

    private isDelayMode = (): string => {
        return this.state.delayMode || this.state.selectingCamera;
    };

    private setUIText = (key: string): void => {
        // The possible values for key are: 'SEARCHING_FOR_FACE', 'DETECTING_FACE', 'FACE_DETECTED',  etc.
        // They are defined in the translations file.
        if (!this.state.processCancelled) this.setState({ ...this.state, userFeedback: key });
    };

    onError(error: string, fromEnrollment: boolean): void {
        console.log('ERROR: ' + error);
        if (error === 'TIMEOUT') {
            this.setState({ ...this.state, timeout: true });
        } else {
            if (fromEnrollment) {
                this.setState({ ...this.state, erroneousRegistration: true, registering: false });
            }
        }
    }

    private async blobToBase64(blob: Blob): Promise<string> {
        const reader = new FileReader();
        return new Promise<string>((resolve, reject) => {
            reader.onloadend = (): void => {
                resolve(reader.result as string);
            };
            reader.onerror = (error): void => {
                reject(error);
            };
            reader.readAsDataURL(blob);
        });
    }

    async onFaceDetected(image: Blob): Promise<void> {
        this.setState({ ...this.state, capturedFace: true, imageToRegister: image });
    }

    handleInputChange = (event: any): void => {
        const value = event.target.value;
        const name = event.target.name;
        this.setState({ ...this.state, [`${name}FormValue`]: value });
    };

    handleSubmit = (event): void => {
        event.preventDefault();
        const userId = this.state.emailFormValue;
        const name = this.state.nameFormValue;
        const email = this.state.emailFormValue;
        this.blobToBase64(this.state.imageToRegister)
            .then(avatar => {
                const createUserObserver = new UserManagementObserver();
                this.setState({ ...this.state, registering: true, error: false });
                this.props.midSDK.createUser(userId, createUserObserver);
                createUserObserver.errors().subscribe(error => {
                    this.onError(error, true);
                });
                createUserObserver.result().subscribe(result => {
                    this.registry
                        .register(userId, name, email, avatar)
                        .then(result => {
                            const enrollUserObserver = new UserManagementObserver();
                            this.props.midSDK.enroll(MIDSDKBiometricMethod.FACE, userId, enrollUserObserver, [
                                this.state.imageToRegister,
                            ]);
                            enrollUserObserver.errors().subscribe(error => {
                                this.onError(error, true);
                            });
                            enrollUserObserver.result().subscribe(result => {
                                this.setState({ ...this.state, successfulRegistration: true, registering: false });
                            });
                        })
                        .catch(() => this.onError(undefined, true));
                });
            })
            .catch((error: Error) => {
                console.log('ERROR in handleSubmit', error);
            });
    };

    componentWillUnmount(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    render(): any {
        return (
            <div id="stepContainer" className="registration">
                <div id="faceContainer"></div>

                <Translation>
                    {t => (
                        <div>
                            <div className="status">
                                <div className="messages-container">
                                    <span
                                        className={
                                            this.state.initialized === false && !this.state.timeout
                                                ? 'message message-enter-done'
                                                : 'message'
                                        }
                                    >
                                        {t('Put your face inside the oval')}
                                    </span>
                                    <CSSTransition in={this.state.timeout} timeout={1000} classNames="message">
                                        <span className="message">{t('TIMEOUT')}</span>
                                    </CSSTransition>
                                    <CSSTransition
                                        in={this.state.initialized && !this.state.capturedFace && !this.state.timeout}
                                        timeout={1000}
                                        classNames="message"
                                    >
                                        <span className="message">{t(this.state.userFeedback)}</span>
                                    </CSSTransition>
                                    <CSSTransition in={this.state.registering} timeout={1000} classNames="message">
                                        <span className="message">{t('Registering...')}</span>
                                    </CSSTransition>
                                    <CSSTransition
                                        in={this.state.successfulRegistration}
                                        timeout={1000}
                                        classNames="message"
                                    >
                                        <span className="message">
                                            {this.state.nameFormValue} <span>{t('has been registered correctly')}</span>
                                        </span>
                                    </CSSTransition>
                                    <CSSTransition
                                        in={this.state.erroneousRegistration}
                                        timeout={1000}
                                        classNames="message"
                                    >
                                        <span className="message">
                                            {t(
                                                'Registration failed, please, try again with another user and e-mail combination',
                                            )}
                                        </span>
                                    </CSSTransition>
                                </div>
                            </div>

                            <form
                                className={
                                    this.state.capturedFace && !this.state.successfulRegistration ? '' : 'hidden'
                                }
                                id="user-registration-form"
                                onSubmit={this.handleSubmit}
                            >
                                <fieldset>
                                    <legend>{t('Registration')}</legend>
                                    <input
                                        type="text"
                                        name="name"
                                        value={this.state.nameFormValue}
                                        onChange={this.handleInputChange}
                                        placeholder="Your Name"
                                        required
                                    ></input>
                                    <input
                                        type="email"
                                        name="email"
                                        value={this.state.emailFormValue}
                                        onChange={this.handleInputChange}
                                        placeholder="user@email.com"
                                        required
                                    ></input>
                                    <input
                                        type="submit"
                                        value={String(t('Register'))}
                                        disabled={this.state.registering}
                                    ></input>
                                </fieldset>
                            </form>

                            <div id="selector-elements-container">
                                <img className="camera-icon" src="assets/img/icon-camera-on.svg" alt="Camera icon" />
                                <div id="mobbid-camera-selector-container"></div>
                                <button id="start-detection-button">{t('Start registration')}</button>
                            </div>

                            <div id="cancel-detection-container">
                                <button
                                    className={this.isDelayMode() ? 'button hidden' : 'hidden'}
                                    id="cancel-detection-button"
                                >
                                    {t('Cancel process')}
                                </button>
                            </div>
                        </div>
                    )}
                </Translation>
            </div>
        );
    }
}
