import * as React from 'react';
import './user-recognizer.css';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { CSSTransition } from 'react-transition-group';
import { IdentificationRegistry } from '../../services/identification-registry';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Translation } from 'react-i18next';
import { MIDSDKBiometricMethod, IdentificationObserver } from '../../../../assets/js/mobbid-sdk-core-1.30.2.js';
import { FaceUIObserver } from '../../../../assets/js/mobbid-sdk-face-1.30.2.js';
import * as DateUtils from '../../../ui/common/date-utils';
import { displayLastVideoImage } from './../../../ui/common/face-utils';

type TranslationFunction = (key: string) => string;

export class UserRecognizer extends React.Component<any, any> {
    private registry: IdentificationRegistry;
    private faceUIObserver = new FaceUIObserver();

    constructor(props: any) {
        super(props);
        this.state = {
            delayMode: this.props.delayMode,
            initialized: false,
            identified: false,
            unidentified: false,
            timeout: false,
            error: undefined,
            eventSent: false,
            detecting: false,
            user: '',
            userFeedback: '',
            userId: '',
            processCancelled: false,
        };
        this.registry = new IdentificationRegistry(this.props.registrationURL);
    }

    componentDidMount(): void {
        const initContainer = this.props.midSDK.setContainer(
            MIDSDKBiometricMethod.FACE,
            document.getElementById('faceContainer'),
            this.props.language,
            this.faceUIObserver,
        );
        initContainer.then(result => {
            this.setState({ ...this.state, initialized: true });
            const identificationObserver = new IdentificationObserver();
            this.props.midSDK.identify(MIDSDKBiometricMethod.FACE, identificationObserver);

            // Identification Observer
            identificationObserver.errors().subscribe(error => {
                this.onError(error);
            });
            identificationObserver.identified().subscribe(result => {
                this.onuserIdentified(result);
            });
            identificationObserver.notIdentified().subscribe(() => {
                this.onUserNotIdentified();
            });

            // Face UI Observer
            this.faceUIObserver.progress().subscribe(progress => {
                this.setState({ ...this.state, error: undefined });

                console.log('PROGRESS: ' + progress);

                if (progress >= 1) {
                    this.setState({ ...this.state, detecting: true });
                    this.setUIText('FACE_DETECTED');
                    if (!this.isDelayMode()) {
                        document.getElementById('cancel-detection-container')?.remove();
                        displayLastVideoImage();
                    }
                } else if (progress > 0) {
                    this.setUIText('DETECTING_FACE');
                } else {
                    this.setUIText('SEARCHING_FOR_FACE');
                }
            });
            this.faceUIObserver.errors().subscribe(error => {
                this.onError(error);
                this.setUIText(error[0]);
            });
            this.faceUIObserver.events().subscribe(event => {
                console.log('EVENTS: ' + event);
                this.setUIText(event);
            });
            this.faceUIObserver.timeouts().subscribe(timeout => {
                console.log('TIMEOUTS: ' + timeout);
            });

            this.configureMobbidSDKElements();
        });
    }

    private configureMobbidSDKElements = (): void => {
        const startDetectionBtn = document.getElementById('start-detection-button');
        const cancelDetectionBtn = document.getElementById('cancel-detection-button');

        startDetectionBtn.addEventListener('click', () => {
            this.setState({ ...this.state, delayMode: false });
            this.setState({ ...this.state, selectingCamera: false });
            this.props.midSDKFace.stopDelayMode();
            document.getElementById('selector-elements-container').remove();
            this.setUIText('SEARCHING_FOR_FACE');
        });

        cancelDetectionBtn.addEventListener('click', () => {
            this.props.midSDKFace.cancelDetection();
            document.getElementById('cancel-detection-container')?.remove();
            this.setUIText('CANCEL_PROCESS');
            this.setState({ ...this.state, processCancelled: true });
        });

        if (window?.mobbid?.devices) {
            this.createCameraSelector(window.mobbid.devices);
        }
    };

    private createCameraSelector = devices => {
        const selector = document.createElement('select');
        selector.id = 'mobbid-camera-selector';

        devices.forEach(device => {
            const option = document.createElement('option');
            option.value = device.deviceId;
            option.text = device.label;
            selector.appendChild(option);
        });

        selector.addEventListener('change', async event => {
            const target = event.target as HTMLInputElement;
            const cameraId = target.value;
            await this.props.midSDKFace.setCamera(cameraId);
        });

        document.getElementById('mobbid-camera-selector-container').append(selector);
    };

    private isDelayMode = (): string => {
        return this.state.delayMode;
    };

    private setUIText = (key: string): void => {
        // The possible values for key are: 'SEARCHING_FOR_FACE', 'DETECTING_FACE', 'FACE_DETECTED',  etc.
        // They are defined in the translations file.
        if (!this.state.processCancelled) this.setState({ ...this.state, userFeedback: key });
    };

    onError(error: string): void {
        console.log('ERROR: ' + error);
        if (error === 'TIMEOUT') {
            this.setState({ ...this.state, timeout: true, detecting: false });
            this.props.onUserNotVerified();
        } else {
            this.setState({ ...this.state, error: error, detecting: false });
        }
    }

    onuserIdentified(result: any): void {
        console.log('IDENTIFIED');
        console.log(result);
        this.setState({ ...this.state, identified: true, detecting: false });
        const id = result.ranking[0].userId;
        let user = id;
        this.registry
            .get(id)
            .then((result: any) => {
                if ('name' in result) {
                    user = result.name;
                }
            })
            .catch(() => {
                // noop
            })
            .finally(() => {
                this.setState({ ...this.state, user: user, userId: id, identified: true });
                this.props.onSuccess(result.ranking[0].score);
            });
    }

    onUserNotIdentified(): void {
        console.log('NOT IDENTIFIED');
        this.setState({ ...this.state, unidentified: true, detecting: false });
    }

    confirmEventSent(): void {
        this.setState({ ...this.state, eventSent: true });
    }

    onConfirm = (): void => {
        this.registry.confirmIdentification(this.state.userId).finally(() => this.confirmEventSent());
    };

    onNotifyError = (): void => {
        this.registry.notifyIdentificationError(this.state.userId).finally(() => this.confirmEventSent());
    };

    render(): JSX.Element {
        return (
            <div id="stepContainer">
                <div id="faceContainer"></div>
                <div className="status">
                    <Translation>
                        {(t: TranslationFunction): JSX.Element => (
                            <div>
                                <CSSTransition in={this.state.identified} timeout={1000} classNames="message">
                                    <span className="message">
                                        {t(DateUtils.getCurrentSpanishGreeting()) + ' ' + this.state.user + '!'}
                                    </span>
                                </CSSTransition>
                                <CSSTransition in={this.state.error} timeout={1000} classNames="message">
                                    <span className="message">{t(this.state.error)}</span>
                                </CSSTransition>
                                <CSSTransition in={this.state.timeout} timeout={1000} classNames="message">
                                    <span className="message">{t('TIMEOUT')}</span>
                                </CSSTransition>
                                <CSSTransition in={this.state.unidentified} timeout={1000} classNames="message">
                                    <span className="message">{t("User can't be identified...")}</span>
                                </CSSTransition>
                                <CSSTransition
                                    in={this.state.initialized && this.state.detecting}
                                    timeout={0}
                                    classNames="message"
                                >
                                    <span className="message">
                                        {t('Identifying user.') + ' ' + t('Please wait...')}
                                    </span>
                                </CSSTransition>
                                <CSSTransition
                                    in={this.state.initialized && this.state.detecting}
                                    timeout={0}
                                    classNames="spinner"
                                >
                                    <img className="spinner" src="assets/img/spinner.png" alt="spinner" />
                                </CSSTransition>
                                <CSSTransition
                                    in={
                                        this.state.initialized &&
                                        !this.state.detecting &&
                                        !this.state.identified &&
                                        !this.state.unidentified &&
                                        !this.state.timeout &&
                                        this.state.error == undefined
                                    }
                                    timeout={0}
                                    classNames="message"
                                >
                                    <span className="message">{t(this.state.userFeedback)}</span>
                                </CSSTransition>
                                <div
                                    className={
                                        this.state.identified && !this.state.eventSent ? 'buttons' : 'buttons hidden'
                                    }
                                >
                                    <button className="success" onClick={this.onConfirm}>
                                        {t('It is me!')}
                                    </button>
                                    <button className="error" onClick={this.onNotifyError}>
                                        {t('It is not me!')}
                                    </button>
                                </div>

                                <div id="selector-elements-container">
                                    <img
                                        className="camera-icon"
                                        src="assets/img/icon-camera-on.svg"
                                        alt="Camera icon"
                                    />
                                    <div id="mobbid-camera-selector-container"></div>
                                    <button id="start-detection-button">{t('Start recognition')}</button>
                                </div>

                                <div id="cancel-detection-container">
                                    <button
                                        className={this.isDelayMode() ? 'button hidden' : 'button'}
                                        id="cancel-detection-button">
                                        {t('Cancel process')}
                                    </button>
                                </div>
                            </div>
                        )}
                    </Translation>
                </div>
            </div>
        );
    }
}
