import * as React from 'react';
import './user-verifier-liveness.css';
import { IdentificationRegistry } from '../../services/identification-registry';
import { MIDSDKBiometricMethod, VerificationObserver } from '../../../../assets/js/mobbid-sdk-core-1.30.2.js';
import { FaceUIObserver } from '../../../../assets/js/mobbid-sdk-face-1.30.2.js';
import { Translation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { displayLastVideoImage } from './../../../ui/common/face-utils';

export class UserVerifierLiveness extends React.Component<any, any> {
    private registry: IdentificationRegistry;
    private faceUIObserver = new FaceUIObserver();

    constructor(props: any) {
        super(props);
        this.state = {
            delayMode: this.props.delayMode,
            initialized: false,
            detecting: false,
            userIdConfirmed: false,
            verified: false,
            unverified: false,
            timeout: false,
            error: undefined,
            eventSent: false,
            user: '',
            userFeedback: '',
            userId: '',
            livenessEvent: null,
            processCancelled: false,
        };
        this.registry = new IdentificationRegistry(this.props.registrationURL);
    }

    onUserIdConfirmed(): void {
        const initContainer = this.props.midSDK.setContainer(
            MIDSDKBiometricMethod.FACE,
            document.getElementById('faceContainer'),
            this.props.language,
            this.faceUIObserver,
        );
        initContainer.then(result => {
            this.setState({ ...this.state, initialized: true });
            const verificationObserver = new VerificationObserver();
            this.props.midSDK.verifyLiveness(
                MIDSDKBiometricMethod.FACE,
                this.state.userId,
                verificationObserver,
                this.props.livenessMode,
            );

            // VerificationObserver
            verificationObserver.errors().subscribe(error => {
                this.onError(error);
            });
            verificationObserver.verified().subscribe(result => {
                this.onUserVerified(result);
            });
            verificationObserver.notVerified().subscribe(result => {
                this.onUserNotVerified(result);
            });

            // FaceUIObserver
            this.faceUIObserver.progress().subscribe(progress => {
                this.setState({ ...this.state, error: undefined });

                console.log('PROGRESS: ' + progress);

                if (progress >= 1) {
                    this.setState({ ...this.state, detecting: true });
                    if (!this.isDelayMode()) {
                        displayLastVideoImage();
                        document.getElementById('cancel-detection-container')?.remove();
                    }
                    this.setUIText('FACE_DETECTED');
                } else if (progress > 0) {
                    this.setUIText('DETECTING_FACE');
                } else {
                    this.setUIText('SEARCHING_FOR_FACE');
                }
            });
            this.faceUIObserver.events().subscribe(event => {
                this.setState({ ...this.state, livenessEvent: event });
                if (event == 'OBTAINING_EVIDENCE') {
                    displayLastVideoImage();
                }
            });
            this.faceUIObserver.errors().subscribe(error => {
                this.onError(error);
                this.setUIText(error[0]);
            });
            this.faceUIObserver.events().subscribe(event => {
                console.log('EVENTS: ' + event);
                this.setUIText(event);
            });
            this.faceUIObserver.timeouts().subscribe(timeout => {
                console.log('TIMEOUTS: ' + timeout);
            });

            this.configureMobbidSDKElements();
        });
    }

    private configureMobbidSDKElements = (): void => {
        const startDetectionBtn = document.getElementById('start-detection-button');
        const cancelDetectionBtn = document.getElementById('cancel-detection-button');

        startDetectionBtn.addEventListener('click', () => {
            this.setState({ ...this.state, delayMode: false });
            this.props.midSDKFace.stopDelayMode();
            document.getElementById('selector-elements-container').remove();
            this.setUIText('SEARCHING_FOR_FACE');
        });

        cancelDetectionBtn.addEventListener('click', () => {
            this.props.midSDKFace.cancelDetection();
            document.getElementById('cancel-detection-container')?.remove();
            this.setUIText('CANCEL_PROCESS');
            this.setState({ ...this.state, processCancelled: true });
        });

        if (window?.mobbid?.devices) {
            this.createCameraSelector(window.mobbid.devices);
        }
    };

    private createCameraSelector = devices => {
        const selector = document.createElement('select');
        selector.id = 'mobbid-camera-selector';

        devices.forEach(device => {
            const option = document.createElement('option');
            option.value = device.deviceId;
            option.text = device.label;
            selector.appendChild(option);
        });

        selector.addEventListener('change', async event => {
            const target = event.target as HTMLInputElement;
            const cameraId = target.value;
            await this.props.midSDKFace.setCamera(cameraId);
        });

        document.getElementById('mobbid-camera-selector-container').prepend(selector);
    };

    private isDelayMode = (): string => {
        return this.state.delayMode || this.state.selectingCamera;
    };

    private setUIText = (key: string): void => {
        // The possible values for key are: 'SEARCHING_FOR_FACE', 'DETECTING_FACE', 'FACE_DETECTED',  etc.
        // They are defined in the translations file.
        if (!this.state.processCancelled) this.setState({ ...this.state, userFeedback: key });
    };

    onError(error: string): void {
        console.log('ERROR: ' + error);

        if (error === 'TIMEOUT') {
            this.setState({ ...this.state, timeout: true, detecting: false });
            this.props.onUserNotVerified();
        } else {
            this.setState({ ...this.state, error: error, detecting: false });
        }
    }

    onUserVerified(result: any): void {
        console.log('VERIFIED');
        this.setState({ ...this.state, verified: true, detecting: false });
        const id = result.userId;
        let user = id;
        this.registry
            .get(id)
            .then((result: any) => {
                if ('name' in result) {
                    user = result.name;
                }
            })
            .catch(() => {
                // noop
            })
            .finally(() => {
                this.setState({ ...this.state, user: user, userId: id, verified: true });
                this.props.onSuccess(result.score);
            });
    }

    onUserNotVerified(result: any): void {
        console.log('NOT VERIFIED');
        this.setState({ ...this.state, unverified: true, detecting: false });
        const id = result.userId;
        let user = id;
        this.registry
            .get(id)
            .then((result: any) => {
                if ('name' in result) {
                    user = result.name;
                }
            })
            .catch(() => {
                // noop
            })
            .finally(() => {
                this.setState({ ...this.state, user: user, userId: id, verified: false });
                this.props.onUserNotVerified(result.score);
            });
    }

    confirmEventSent(): void {
        this.setState({ ...this.state, eventSent: true });
    }

    handleInputChange = (event: any) => {
        const value = event.target.value;
        this.setState({ ...this.state, userId: value });
    };

    handleSubmit = (event: any) => {
        this.registry
            .get(this.state.userId)
            .then((result: any) => {
                if (result['email'] === this.state.userId) {
                    this.setState({ ...this.state, userIdConfirmed: true, unknownUser: false, user: result['name'] });
                    this.onUserIdConfirmed();
                } else {
                    this.setState({ ...this.state, unknownUser: true });
                    this.props.onUnknownUser();
                }
            })
            .catch(() => {
                // noop
            });
        event.preventDefault();
    };

    getlivenessEventText = () => {
        let value = '';
        switch (this.state.livenessEvent) {
            case 'CENTER_START': {
                value = 'Turn your head as shown in the figure';
                break;
            }
            case 'LEFT': {
                value = 'Turn your head to the left';
                break;
            }
            case 'RIGHT': {
                value = 'Turn your head to the right';
                break;
            }
            case 'CENTER_END': {
                value = 'Look straight ahead';
                break;
            }
            case 'PASSIVE_START': {
                value = "Keep looking straight ahead and don't move";
                break;
            }
            default: {
                value = 'Verifying user.';
                break;
            }
        }
        return value;
    };

    render(): any {
        return (
            <div id="user-verifier-liveness">
                {this.state.userIdConfirmed === false ? (
                    <Translation>
                        {t => (
                            <div>
                                <form onSubmit={this.handleSubmit}>
                                    <fieldset>
                                        <legend>{t('Insert your e-mail')}</legend>
                                        <input
                                            type="email"
                                            name="email"
                                            value={this.state.emailFormValue}
                                            placeholder="user@email.com"
                                            onChange={this.handleInputChange}
                                            required
                                        ></input>
                                        <input
                                            type="submit"
                                            value={String(t('Next'))}
                                            disabled={this.state.registering}
                                        ></input>
                                    </fieldset>
                                </form>
                                <div className="status">
                                    <Translation>
                                        {t => (
                                            <div>
                                                <CSSTransition
                                                    in={this.state.unknownUser}
                                                    timeout={1000}
                                                    classNames="message"
                                                >
                                                    <span className="message">{t('User does not exists')}...</span>
                                                </CSSTransition>
                                            </div>
                                        )}
                                    </Translation>
                                </div>
                            </div>
                        )}
                    </Translation>
                ) : (
                    <div id="stepContainer">
                        <div id="faceContainer"></div>
                        <div className="status">
                            <Translation>
                                {t => (
                                    <div>
                                        <div className="messages-container">
                                            <CSSTransition in={this.state.error} timeout={1000} classNames="message">
                                                <span className="message">{t(this.state.error)}</span>
                                            </CSSTransition>
                                            <CSSTransition in={this.state.timeout} timeout={1000} classNames="message">
                                                <span className="message">{t('TIMEOUT')}</span>
                                            </CSSTransition>
                                            <CSSTransition
                                                in={this.state.unverified}
                                                timeout={1000}
                                                classNames="message"
                                            >
                                                <span className="message">
                                                    {t('User not verified.').replace(' ', ' ' + this.state.user + ' ')}
                                                </span>
                                            </CSSTransition>
                                            <CSSTransition in={this.state.verified} timeout={1000} classNames="message">
                                                <span className="message">
                                                    {t('User verified.').replace(' ', ' ' + this.state.user + ' ')}
                                                </span>
                                            </CSSTransition>
                                            <CSSTransition
                                                in={
                                                    this.state.initialized &&
                                                    this.state.detecting &&
                                                    this.state.livenessEvent == null
                                                }
                                                timeout={0}
                                                classNames="message"
                                            >
                                                <span className="message">{t('Detecting user.')}</span>
                                            </CSSTransition>
                                            <CSSTransition
                                                in={
                                                    this.state.initialized &&
                                                    this.state.detecting &&
                                                    this.state.livenessEvent != null
                                                }
                                                timeout={0}
                                                classNames="message"
                                            >
                                                <span className="message">
                                                    {t(this.getlivenessEventText()) +
                                                        (this.state.livenessEvent == 'START_VERIFICATION'
                                                            ? ' ' + t('Please wait...')
                                                            : '')}
                                                </span>
                                            </CSSTransition>
                                            <CSSTransition
                                                in={
                                                    this.state.initialized &&
                                                    this.state.detecting &&
                                                    this.state.livenessEvent == 'START_VERIFICATION'
                                                }
                                                timeout={0}
                                                classNames="spinner"
                                            >
                                                <img className="spinner" src="assets/img/spinner.png" alt="spinner" />
                                            </CSSTransition>
                                            <CSSTransition
                                                in={
                                                    this.state.initialized &&
                                                    !this.state.detecting &&
                                                    !this.state.verified &&
                                                    !this.state.unverified &&
                                                    !this.state.timeout &&
                                                    this.state.error == undefined
                                                }
                                                timeout={0}
                                                classNames="message"
                                            >
                                                <span className="message">{t(this.state.userFeedback)}</span>
                                            </CSSTransition>
                                        </div>

                                        <div id="selector-elements-container">
                                            <img
                                                className="camera-icon"
                                                src="assets/img/icon-camera-on.svg"
                                                alt="Camera icon"
                                            />
                                            <div id="mobbid-camera-selector-container"></div>
                                            <button id="start-detection-button">{t('Start verification')}</button>
                                        </div>

                                        <div id="cancel-detection-container">
                                            <button
                                                className={this.isDelayMode() ? 'hidden' : ''}
                                                id="cancel-detection-button"
                                            >
                                                {t('Cancel process')}
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </Translation>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
