import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './face-recognition-demo.css';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { UserRecognizer } from '../user-recognizer/user-recognizer';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { UserRegistration } from '../user-registration/user-registration';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { UserVerifier } from '../user-verifier/user-verifier';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { UserVerifierLiveness } from '../user-verifier-liveness/user-verifier-liveness';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Translation } from 'react-i18next';
import { configureI18Next } from '../../services/i18n';
import { MIDSDK, MIDSDKBiometricMethod } from '../../../../assets/js/mobbid-sdk-core-1.30.2.js';
import { MIDSDKFace } from '../../../../assets/js/mobbid-sdk-face-1.30.2.js';
import { IdentificationRegistry } from '../../services/identification-registry';

interface FaceRecognitionDemoProps {
    baseURL: string;
    applicationId: string;
    registrationURL: string;
    logoURL: string;
    language: string;
}

declare global {
    interface Window {
        mobbid: any;
    }
}

const getRandomKey = () =>
    Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '')
        .substr(0, 5);

export class FaceRecognitionDemo extends React.Component<FaceRecognitionDemoProps, any> {
    private delayMode = true;
    private midSDK = null;
    private midSDKFace = null;
    private registry = new IdentificationRegistry(this.props.registrationURL);

    constructor(props: any) {
        super(props);
        this.state = {
            registering: false,
            identifying: false,
            verifying: false,
            verifyingLiveness: false,
            recognitionKey: getRandomKey(),
            initialized: false,
            livenessMode: 0,
            userNotVerified: false,
            score: undefined,
        };

        this.registry.getAuthToken().then(response => {
            this.midSDK = new MIDSDK(this.props.baseURL, response.authToken, this.props.applicationId);
            this.midSDKFace = new MIDSDKFace({
                allowCameraSelector: true,
                delayMode: this.delayMode,
                minSuccessfulDetections: 2,
                timeoutDuration: 30000,
            });
            this.midSDK.addBiometricMethod(MIDSDKBiometricMethod.FACE, this.midSDKFace);
        });
    }

    register(): void {
        this.setState({ ...this.state, registering: true });
    }

    onUserIdentified = (score: any): void => {
        console.log('identified user');
        this.setState({ ...this.state, score: score, detectedUser: true, unknownUser: false, userNotVerified: false });
    };

    onUnknownUser = (): void => {
        console.log('unknown user');
        this.setState({ ...this.state, detectedUser: false, unknownUser: true });
    };

    onUserVerified = (score: any): void => {
        console.log('user verified');
        this.setState({ ...this.state, detectedUser: true, unknownUser: false, userNotVerified: false, score: score });
    };

    onUserNotVerified = (score: any): void => {
        console.log('user not verified');
        this.setState({ ...this.state, detectedUser: true, unknownUser: false, userNotVerified: true, score: score });
    };

    onRegister = (): void => {
        this.setState({ ...this.state, registering: true, initialized: true });
    };

    onRestart = (): void => {
        location.reload();
    };

    onRetry = (): void => {
        this.setState({
            ...this.state,
            user: '',
            detectedUser: false,
            unknownUser: false,
            registering: false,
            userNotVerified: false,
            recognitionKey: getRandomKey(),
            score: undefined,
        });
    };

    onVerify = (): void => {
        this.setState({
            ...this.state,
            initialized: true,
            verifying: true,
        });
    };

    onVerifyLivenessHeadMovement = (): void => {
        this.setState({
            ...this.state,
            initialized: true,
            verifyingLiveness: true,
            livenessMode: 0,
        });
    };

    onVerifyLivenessHeadStaticVideo = (): void => {
        this.setState({
            ...this.state,
            initialized: true,
            verifyingLiveness: true,
            livenessMode: 1,
        });
    };

    onVerifyLivenessHeadStaticImage = (): void => {
        this.setState({
            ...this.state,
            initialized: true,
            verifyingLiveness: true,
            livenessMode: 2,
        });
    };

    onIdentify = (): void => {
        this.setState({
            ...this.state,
            initialized: true,
            identifying: true,
        });
    };

    getCurrentStep(): JSX.Element {
        const recognitionStep = (
            <UserRecognizer
                delayMode={this.delayMode}
                midSDK={this.midSDK}
                midSDKFace={this.midSDKFace}
                registrationURL={this.props.registrationURL}
                onSuccess={this.onUserIdentified}
                onUnknownUser={this.onUnknownUser}
                key={this.state.recognitionKey}
                language={this.props.language}
            ></UserRecognizer>
        );

        const verificationStep = (
            <UserVerifier
                delayMode={this.delayMode}
                midSDK={this.midSDK}
                midSDKFace={this.midSDKFace}
                registrationURL={this.props.registrationURL}
                onSuccess={this.onUserVerified}
                onUnknownUser={this.onUnknownUser}
                onUserNotVerified={this.onUserNotVerified}
                key={this.state.recognitionKey}
                language={this.props.language}
            ></UserVerifier>
        );

        const verificationLivenessStep = (
            <UserVerifierLiveness
                delayMode={this.delayMode}
                midSDK={this.midSDK}
                midSDKFace={this.midSDKFace}
                registrationURL={this.props.registrationURL}
                onSuccess={this.onUserVerified}
                onUnknownUser={this.onUnknownUser}
                onUserNotVerified={this.onUserNotVerified}
                key={this.state.recognitionKey}
                livenessMode={this.state.livenessMode}
                language={this.props.language}
            ></UserVerifierLiveness>
        );

        const registrationStep = (
            <UserRegistration
                delayMode={this.delayMode}
                midSDK={this.midSDK}
                midSDKFace={this.midSDKFace}
                registrationURL={this.props.registrationURL}
                applicationId={this.props.applicationId}
                language={this.props.language}
            ></UserRegistration>
        );

        return this.state.registering
            ? registrationStep
            : this.state.identifying
            ? recognitionStep
            : this.state.verifyingLiveness
            ? verificationLivenessStep
            : verificationStep;
    }

    getScore(): JSX.Element {
        if (this.state.score == undefined) {
            return undefined;
        } else if (this.state.score.liveness == undefined) {
            return (
                <div className="score">
                    <h3>Score: {Math.round(this.state.score.identityVerification * 100)}%</h3>
                </div>
            );
        } else {
            return (
                <div className="score">
                    <h3 className="m-0">Score: {Math.round(this.state.score.identityVerification * 100)}%</h3>
                    <ul>
                        <li>Matching: {Math.round(this.state.score.faceMatching * 100)}%</li>
                        <li>Liveness: {Math.round(this.state.score.liveness * 100)}%</li>
                    </ul>
                </div>
            );
        }
    }

    render(): JSX.Element {
        return (
            <Translation>
                {t => (
                    <div>
                        {this.state.initialized === false ? (
                            <div id="welcome">
                                <img className="logo" src={this.props.logoURL} alt="Logo"></img>
                                <h1>{t('User recognition')}</h1>
                                <p className="summary">{t('welcome.message')}</p>
                                <div className="welcome-buttons">
                                    <button onClick={this.onRegister}>{t('Register')}</button>
                                    <button onClick={this.onVerifyLivenessHeadStaticImage}>
                                        {t('Verify using passive antispoofing')}
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className="face-recognition-demo">
                                <h1>
                                    {this.state.registering
                                        ? t('Registration')
                                        : this.state.identifying
                                        ? t('Searching user')
                                        : t('Verifying user')}
                                </h1>
                                {this.getCurrentStep()}
                                <div className="info-container">
                                    {this.getScore()}
                                    <div>
                                        <button
                                            className={
                                                this.state.unknownUser || this.state.userNotVerified ? '' : 'hidden'
                                            }
                                            id="restart-button"
                                            onClick={this.onRetry}
                                        >
                                            {this.state.registering ? t('Go back') : t('Retry')}
                                        </button>
                                        <a className="button" href="#" onClick={this.onRestart} id="homeButton">
                                            <span className="material-symbols-outlined">arrow_back</span>
                                        </a>
                                        <button
                                            className={
                                                this.state.unknownUser && !this.state.registering ? '' : 'hidden'
                                            }
                                            onClick={this.onRegister}
                                        >
                                            {t('Add new user')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </Translation>
        );
    }
}

export function renderDemo(
    parent: HTMLElement,
    baseURL: string,
    registrationURL: string,
    applicationId: string,
    logoURL: string,
    language: string,
): void {
    const availableLanguage = language == 'es' ? language : 'en';
    configureI18Next(availableLanguage);
    ReactDOM.render(
        <FaceRecognitionDemo
            baseURL={baseURL}
            applicationId={applicationId}
            registrationURL={registrationURL}
            logoURL={logoURL}
            language={availableLanguage}
        />,
        parent,
    );
}
