export function displayLastVideoImage(): void {
    const video = document.getElementsByTagName('video')[0] as HTMLVideoElement;
    const canvases = Array.from(
        document.querySelectorAll('canvas[id^="mobbid-web-image-source-video-"]'),
    ) as HTMLCanvasElement[];
    const canvas = canvases[canvases.length - 1] as HTMLCanvasElement;
    const overlay = document.getElementsByClassName('mobbid-face-detector-overlay')[0] as HTMLDivElement;

    if (canvas) {
        canvas.style.width = overlay.style.width;
        canvas.style.height = overlay.style.height;
        canvas.style.marginLeft = overlay.style.marginLeft;
        canvas.style.marginTop = overlay.style.marginTop;
        canvas.style.transform = video.style.transform;
        overlay.style.marginTop = '0';
        video.style.display = 'none';
        canvas.style.display = 'block';
    } else {
        console.error('No appropriate canvas found');
    }
}
